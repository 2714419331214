::v-deep .el-table th {
  padding: 0px ;
}
::v-deep .el-table thead {
  height: 15px !important;
  color: #474646;
  font-size: 12px;
}
::v-deep .el-table td {
  padding: 3px ;
  font-size: 11px;
}
.el-aside {
  height: calc(100vh - 100px);
}
.card-div {
  padding: 14px;
  background: #EBEEF5;
  text-align: center;
}
.cardhover:hover {
  border: #409EFF 1px solid;
}
.img-div {
  text-overflow: ellipsis;
  /*对超出容器的部分强制截取，高度不确定则换行*/
  overflow: hidden;
  /*显示省略符号来代表被修剪的文本。*/
  white-space: nowrap;
  line-height: 20px;
}
.top {
  padding: 10px;
  background: rgba(0, 153, 229);
  color: #fff;
  text-align: center;
  font-size: 10px;
  border-radius: 2px;
}
.group-css {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 1230px;
}
.wraper {
  width: 1250px;
  margin: 1px auto;
}
.description {
  width: 100%;
  position: absolute;
  z-index: 2;
  left: 0;
  bottom: 0;
  text-align: center;
  line-height: 35px;
  color: #3311f7;
  background-color: #b7e9eb;
}
.img-contain {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  margin-left: 2px;
  border: 1px solid #ccc;
  position: relative;
  width: 300px;
  height: 280px;
}
.video-small {
  height: 278px;
  width: 298px;
  padding: 5px auto;
  border-radius: 10px;
}
