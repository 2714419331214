

























































































































































































































































































































































































































































































































































.el-dialog__body{
    padding: 10px;
}
.el-dialog__header{
    padding: 0px;
}
.el-dialog__footer{
    padding: 5px;
    border-top:0px solid #ccc;
} 
.el-dialog__headerbtn{font-size: 20px; background:white;top:10px !important;}
// .el-dialog__headerbtn i { background:white;font-size: 20px;  }
