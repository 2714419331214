.el-aside {
  height: calc(100vh - 100px);
}
.card-div {
  padding: 14px;
  background: #EBEEF5;
  text-align: center;
}
.cardhover:hover {
  border: #409EFF 1px solid;
}
.img-div {
  text-overflow: ellipsis;
  /*对超出容器的部分强制截取，高度不确定则换行*/
  overflow: hidden;
  /*显示省略符号来代表被修剪的文本。*/
  white-space: nowrap;
  line-height: 20px;
}
.top {
  padding: 10px;
  background: rgba(0, 153, 229);
  color: #fff;
  text-align: center;
  font-size: 10px;
  border-radius: 2px;
}
.group-css {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 1230px;
}
.wraper {
  width: 1250px;
  margin: 1px auto;
}
